import React from "react";
import './../App.css';
import AboutSection from "../components/AboutSection";

export default function About() {
    return(
        <>
        <AboutSection />
        </>
    )
}
