import React from "react";
import ContactForm from "../components/ContactForm";
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
//import './Contact.css';

export default function Contact() {

    return (
    
    <div>
        <ContactForm />
    </div>
    )
}
