import React from 'react'
import Cards from '../components/Cards'

function Links() {
    return (
        <div>
            <Cards />
        </div>
    )
}

export default Links
