import React from 'react'
import './../App.css';
import GratitudeSection from '../components/GratitudeSection'

function Gratitude() {
    return (
        <div>
            <GratitudeSection />
        </div>
    )
}

export default Gratitude
